import React from "react";
import PropTypes from "prop-types";
import Heading from "atoms/Heading";
import Text from "atoms/Text";
import Image from "next/image";

const LoginContent = ({ loginContentHeading, loginContentDesc }) => {
  const myLoader = ({ src }) => src;
  return (
    <div className="md:min-h-screen h-[43vh] relative p-6 py-8 md:py-16 md:px-9 overflow-hidden bg- md:bg-transparent">
      <div className="md:absolute md:top-0 md:left-0 md:p-6 z-10 max-w-md mx-auto md:mx-0 text-center md:text-left mt-4">
        <Image
          id='img_tag_148'
          loader={myLoader}
          src="/images/logo-black.png"
          width="95"
          height="35"
          alt="logo"
        />
        <Heading
          type="h3"
          className="text-2xl md:text-3xl md:mt-24 mt-5 mb-4 text-white"
        >
          {loginContentHeading}
        </Heading>
        <Text className="text-white">{loginContentDesc}</Text>
      </div>

      <div className="hidden md:block min-h-screen absolute w-full h-full top-0 left-0 z-0 login-bg">
        <Image
          id='img_tag_149'
          loader={myLoader}
          src="/images/Login-Page.png"
          layout="fill"
          objectFit="cover"
          alt="login bg"
        />
      </div>
      
      <div className="block md:hidden min-h-screen absolute w-full h-full top-0 left-0 z-0 login-bg">
        <Image
          id='img_tag_150'
          loader={myLoader}
          src="/images/Login-Mobile.png"
          layout="fill"
          objectFit="contain"
          alt="login bg"
          objectPosition="center"
        />
      </div>

      <div className="md:flex items-center justify-center md:min-h-screen md:absolute w-full md:h-full h-[15vh] md:top-0 md:left-0 z-10">
        <div className="text-center">
          <p className="md:text-6xl text-5xl md:font-thin mt-12 font-light text-black font-san">
            WELCOME TO
          </p>
          <div className="mt-3 md:space-y-4">
            <p className="font-bold leading-[74px] md:text-6xl text-6xl md:mt-9 text-black font-san tracking-normal">Loop</p>
          </div>
        </div>
      </div>
    </div>
  );
};

LoginContent.propTypes = {
  loginContentHeading: PropTypes.string,
  loginContentDesc: PropTypes.string,
};

export default LoginContent;
